import { lazy } from 'react'

import trackEvent from 'lib/trackEvent'

export const lazyWithErrorHandling = (
  importFunc: () => Promise<{ default: () => React.ReactElement | null }>
) =>
  lazy(() =>
    importFunc().catch((err) => {
      window.console.error(err)
      return {
        default: () => (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              marginTop: '20px',
            }}
          >
            <div>Unable to load</div>
            <br />
            <div
              style={{
                cursor: 'pointer',
                border: '1px solid rgb(204, 204, 204)',
                padding: '6px',
                borderRadius: '4px',
                backgroundColor: '#646363',
                color: 'white',
              }}
              onClick={() => {
                try {
                  trackEvent(
                    trackEvent.modules.auth,
                    trackEvent.events.reload_lazy_click
                  )
                  window.location.reload()
                } catch (error) {
                  console.error('Error tracking event:', error)
                }
              }}
            >
              RELOAD
            </div>
          </div>
        ),
      }
    })
  )
